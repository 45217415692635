@use "../../utils/variables" as vars;
@import "../../styles/fonts.scss";

.container {
  color: vars.$blue;
  padding-top: 56px;
  padding-bottom: 120px;
  @media screen and (max-width: vars.$big) {
    padding-top: calc(56px / vars.$big * 100vw);
    padding-bottom: calc(120px / vars.$big * 100vw);
  }

  @media screen and (max-width: vars.$medium) {
    padding-top: calc(40px / vars.$medium * 100vw);
    padding-bottom: calc(80px / vars.$medium * 100vw);
  }

  @media screen and (max-width: vars.$small) {
    row-gap: calc(50px / vars.$small * vars.$smallCoef * 100vw);
    padding-top: calc(60px / vars.$small * vars.$smallCoef * 100vw);
    padding-bottom: calc(60px / vars.$small * vars.$smallCoef * 100vw);
  }
}

.title {
  grid-column: 1 / 4;
  @extend .H4;
  @media screen and (max-width: vars.$medium) {
    grid-column: 1 / 2;
  }

  @media screen and (max-width: vars.$small) {
    grid-column: 1 / -1;
  }
}

.desc {
  grid-column: 4 / 13;
  max-width: 1133px;
  text-transform: uppercase;
  @extend .H3;
  @media screen and (max-width: vars.$big) {
    max-width: calc(1133px / vars.$big * 100vw);
  }

  @media screen and (max-width: vars.$medium) {
    grid-column: 2 / 5;
    max-width: none;
  }

  @media screen and (max-width: vars.$small) {
    grid-column: 1 / -1;
  }
}
