@use "../../utils/variables" as vars;

.btn {
  grid-column: 4 / 13;
  align-self: start;
  margin-top: -40px;
  @media screen and (max-width: vars.$big) {
    margin-top: calc(-40px / vars.$big * 100vw);
  }

  @media screen and (max-width: vars.$medium) {
    margin-top: calc(-40px / vars.$medium * 100vw);
    grid-column: 2 / 5;
  }

  @media screen and (max-width: vars.$small) {
    margin-top: calc(-40px / vars.$small * vars.$smallCoef * 100vw);
    grid-column: 1 / -1;
  }
}

.img {
  grid-column: 4 / 13;
  grid-row: 2 / 3;
  width: 100%;
  height: auto;
  border-radius: 40px;
  object-fit: cover;
  margin-top: 120px;
  margin-bottom: 160px;
  @media screen and (max-width: vars.$big) {
    margin-top: calc(120px / vars.$big * 100vw);
    margin-bottom: calc(160px / vars.$big * 100vw);
    border-radius: calc(40px / vars.$big * 100vw);
  }

  @media screen and (max-width: vars.$medium) {
    margin-top: calc(80px / vars.$medium * 100vw);
    margin-bottom: calc(120px / vars.$medium * 100vw);
    border-radius: calc(30px / vars.$medium * 100vw);
    grid-column: 2 / 5;
  }

  @media screen and (max-width: vars.$small) {
    margin-top: calc(40px / vars.$small * vars.$smallCoef * 100vw);
    margin-bottom: calc(80px / vars.$small * vars.$smallCoef * 100vw);
    border-radius: calc(20px / vars.$small * vars.$smallCoef * 100vw);
    grid-column: 1 / -1;
  }
}

.link {
  &:hover {
    color: vars.$black;
  }
}
